import { initFederation } from '@angular-architects/native-federation';
import { environment } from './environments/environment';

initFederation(
    environment.production
        ? '/assets/federation.manifest.json'
        : '/assets/federation.manifest.dev.json'
)
    .catch((err) => console.error(err)) // eslint-disable-line no-console
    .then((_) => import('./bootstrap'))
    .catch((err) => console.error(err)); // eslint-disable-line no-console
